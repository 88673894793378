<template>
  <div class="products">
    <div class="three-btns container mb-4">
      <button @click="$router.push('/')">{{ $t("Main") }} /</button>
      <button>{{ $t("Offers") }} /</button>
      <button>{{ $getByLang(one.name) }}</button>
    </div>
    <div class="change-home position-relative mb-5">
      <div class="container d-flex justify-content-around flex-wrap">
        <p class="mt-3 mb-5">
          {{ $getByLang(one.name)}}
          <!-- <span> {{ $t("end") }} {{ $formatDateOffers(one.toDate) }} </!--> 
          <span class="d-block">
            <span>{{ $t("with") }}</span>
            {{ $t("home her") }}
          </span>
        </p>
        <!-- <img
          class="mb-5"
          :src="$baseUploadURL + one.offerImage"
          :alt="$getByLang(one.name)"
          style="max-width: 313px"
        /> -->
      </div>
      <!-- <div class="background position-absolute bacj"></div> -->
    </div>

    <!-- categories and recently arrived -->
    <div class="categories mt-11 mt-5 px-4">
      <div class="row">
        <div class="col-md-3 col-xs-12">
          <button
            @click="() => (showFilter = !showFilter)"
            class="p-2 rounded d-lg-none d-flex align-items-center gap-2"
          >
            <span class="pi pi-filter"></span>
            <!-- <span>Filter</span> -->
          </button>
          <div class="sidebarsearch mt-2 p-3" v-if="showFilter">
            <div class="mb-3">
              <h3>{{ $t("Categories") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of categoriesList"
                :key="'categories_' + item._id"
              >
                <Checkbox
                  :id="'categories_' + item._id"
                  name="categoriesId"
                  :value="item._id"
                  v-model="body.categoriesId"
                  :inputId="'categories_' + item._id"
                />
                <label :for="'categories_' + item._id">{{
                  $getByLang(item.name)
                }}</label>
              </div>
            </div>
            <div class="mb-3">
              <h3>{{ $t("sections") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of subcategoriesList"
                :key="'subcategories_' + item._id"
              >
                <Checkbox
                  :id="'subcategories_' + item._id"
                  name="subCategoriesId"
                  :value="item._id"
                  v-model="body.subCategoriesId"
                  :inputId="'subcategories_' + item._id"
                />
                <label :for="'subcategories_' + item._id">{{
                  $getByLang(item.name)
                }}</label>
              </div>
            </div>

          

            <div class="mb-3">
              <h3>{{ $t("Colors") }}</h3>

              <span v-for="item of colorsList" :key="'colors_' + item._id">
                <input
                  type="radio"
                  class="btn-check"
                  name="colorId"
                  :id="'colors_' + item._id"
                  autocomplete="off"
                  :value="item._id"
                  v-model="body.colorId"
                />
                <label
                  class="btn btn-color"
                  :for="'colors_' + item._id"
                  :style="'border-color: ' + item.value + ' !important'"
                >
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11.5" cy="11.5" r="11.5" :fill="item.value" />
                  </svg>
                </label>
              </span>
            </div>
            <div class="mb-3">
              <h3>{{ $t("the price") }}</h3>
              <div class="row mb-4">
                <div class="col-md-6">{{ Math.round(body.price[1]) }}{{ $t("SAR") }}</div>
                <div class="col-md-6 text-left">
                  {{ Math.round(body.price[0]) }}{{ $t("SAR") }}
                </div>
              </div>
              <div>
                <Slider
                  v-model="body.price"
                  :min="0"
                  :max="100000"
                  :range="true"
                />
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("resources") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of materialsList"
                :key="'materials_' + item._id"
              >
                <Checkbox
                  :id="'materials_' + item._id"
                  name="materialsId"
                  :value="item._id"
                  v-model="body.materialsId"
                  :inputId="'materials_' + item._id"
                />
                <label :for="'materials_' + item._id">
                  {{ $getByLang(item.name) }}
                </label>
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("the shop") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of vendorsList"
                :key="'vendors_' + item._id"
              >
                <Checkbox
                  :id="'vendors_' + item._id"
                  name="vendorsId"
                  :value="item._id"
                  v-model="body.vendorsId"
                  :inputId="'vendors_' + item._id"
                />
                <label :for="'vendors_' + item._id">
                  {{ item.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-xs-12">
          <div class="new m-2">
            <h1 class="cat-head mb-4">{{ $t("offer details") }}</h1>
            <div style="min-height: 700px">
              <div class="m-5 text-center" v-if="list.length == 0">
                <img
                  src="../../assets/images/notFoundIllustration.svg"
                  class="mt-5"
                  alt=""
                  style="max-width: 300px"
                />
              </div>
              <div class="row"  v-if="filteredData.length > 0">
                <div class="col-md-4 mb-2 p-3" v-for="item in filteredData" :key="item._id">
                  <div
                    class="newCard"
                    
                    @click="navigateToItem(item)"
                  >
                    <span class="itemofferdbyvalue">
                      {{ $t("rival") }}
                      {{ item.offerValue }}%
                    </span>

                    <img
                      class="logo"
                      :src="
                        item.itemObj.colorsList.length > 0 &&
                        item.itemObj.colorsList[0].colorsImage.length > 0
                          ? $baseUploadURL +
                            item.itemObj.colorsList[0].colorsImage[0].name
                          : ''
                      "
                      :alt="$getByLang(item.itemName)"
                    />

                    <div class="newCard-body text-truncate">
                      <h5>{{$textSlice($getByLang(item.itemName), 40) }}</h5>
                      <b-form-rating
                        v-model="item.rate"
                        variant="warning"
                        class="mb-2"
                        readonly
                        inline
                        no-border
                        size="sm"
                        locale="ar-EG"
                      ></b-form-rating>
                      <span> ({{ item.itemObj.rateAmount }}) </span>

                      <p>
                        {{
                          Math.round(
                           item.priceBeforeOffer - item.priceBeforeOffer * (item.offerValue / 100))
                        }}
                        {{ $t("r.s") }}
                        <del class="offeroldprice">
                          {{ Math.round(item.priceBeforeOffer) }}
                          {{ $t("r.s") }}
                        </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <Paginator  :pageNumber="pageNum"
              v-if="list.length > 0"
              style="direction: ltr"
              :rows="limit"
              :totalRecords="totalItemsCount"
              @page="onPage($event)"
            ></Paginator> -->
            <infinite-loading @infinite="loadMore" :identifier="pageNum">
              <div slot="no-more">{{ $t('No more data') }}</div>
              <div slot="no-results">{{ $t('No results') }}</div>
            </infinite-loading>




          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: { InfiniteLoading },
  head: {
    title: function () {
      return {
        inner: this.$getByLang(this.one.name),
      }

    },
  },
  data() {
    return {
      pageNum: 1, // الصفحة الحالية
    pageSize: 12, // عدد العناصر في كل صفحة
    allDataLoaded: false, // التحقق من انتهاء البيانات
        
          selectedItemsFromOffer:[],
      body: {
        categoriesId: [],
        subCategoriesId: [],
        colorsList: {
          colorId: null
        },
        sizes: {
          sizeId: []
        },
       price: [0, 100000],
        vendorsId: [],
        materialsId: []
      },
      categoriesList: [],
      subcategoriesList: [],
      sizeList: [],
      colorsList: [],
      vendorsList: [],

      materialsList: [],
      list: [],
      totalItemsCount: 0,

      limit: 0,
      page: 1,
      id: null,
      one: {
        name: {
          ar: null,
          en: null
        },
        bannerSubCategoryImage: null,
        value: 0
      },

      showFilter: false
    };
  },
  mounted() {
    if (window.innerWidth > 992) {
      this.showFilter = true;
    }
  },
  methods: {
    navigateToItem(data){
localStorage.setItem("sizeId",data.sizeId._id)
this.$router.push('/product/'+data.itemId);
    },
    $goToSave(link) {
      sessionStorage.setItem("homeHerePageOffer", "offers");
      sessionStorage.setItem("homeHerePageNumberOffer", this.pageNum);
      this.$router.push(link);
    },
    addLike(item) {
      if (localStorage.homeHere) {
        this.$http
          .post("favourite", {
            itemId: item._id
          })
          .then((res) => {
            item.imLikedThis = !item.imLikedThis;
            this.$swal({
              title: this.$t("Successfully Done"),
              icon: "success",
              timer: 3000,
              showConfirmButton: false
            });
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: "Must be loged in",
          life: 3000
        });
      }
    },
    onPage(event) {
     this.pageNum = event.page + 1;
      this.getData(event.page + 1);
      sessionStorage.setItem("homeHerePageOffer", "offers");
      sessionStorage.setItem("homeHerePageNumberOffer", this.pageNum);
    
    },
    async loadMore($state) {
    try {
      console.log("this.pageSize" , this.pageSize);
      console.log("this.pageNum" , this.body);
      
      const res = await this.$http.post(`offers/getOfferDetailsById?limit=${this.pageSize}&page=${this.pageNum}`, {
        offerId: this.id,
        categoriesId: this.body.categoriesId,
        subCategoriesId: this.body.subCategoriesId,
        sizeId: this.body.sizeId,
        colorId: this.body.colorId,
        vendorsId: this.body.vendorsId,
        materialsId: this.body.materialsId
      });

      if (res.data.docs.length > 0) {
        // إضافة العناصر إلى القائمة الحالية
        this.list = [...this.list, ...res.data.docs];
        this.selectedItemsFromOffer = this.list;
        
        this.pageNum += 1; // زيادة رقم الصفحة للتحميل في المرة القادمة
        
        // تحقق مما إذا كان هناك صفحات أخرى
        if (!res.data.hasNextPage) {
          this.allDataLoaded = true;
          $state.complete();
        } else {
          $state.loaded(); // إبلاغ InfiniteLoading بتحميل البيانات
        }
      } else {
        this.allDataLoaded = true; // تعيين العلم بانتهاء جميع البيانات
        $state.complete(); // إبلاغ InfiniteLoading بانتهاء التحميل
      }
    } catch (error) {
      console.error("Error loading more data:", error);
      $state.complete(); // إبلاغ InfiniteLoading بوجود خطأ
    }
  },
  resetAndReload() {
    this.pageNum = 1;
    this.allDataLoaded = false;
    this.list = [];
    this.loadMore({
      loaded: () => {},
      complete: () => {}
    });
  },
     async getData2(page = 1) {
        let obj = {};
        if (this.q) obj.q = this.q;

        if (this.body.categoriesId.length > 0) {
            obj.categoriesId = { $in: this.body.categoriesId };
        }
        if (this.body.subCategoriesId.length > 0) {
            obj.subCategoriesId = { $in: this.body.subCategoriesId };
        }
        if (this.body.vendorsId && this.body.vendorsId.length > 0) {
            obj.vendorsId = { $in: this.body.vendorsId };
        }
        if (this.body.materialsId.length > 0) {
            obj.materialsId = { $in: this.body.materialsId };
        }
        
        if (this.body.colorsList.colorId) {
            obj.colorsList = { colorId: this.body.colorsList.colorId };
        }

        // ضبط نطاق السعر إذا كان محددًا
        if (this.body.price[0] !== 0 && this.body.price[1] <= 77000) {
            obj.sizes.mainPrice = {
                $gte: this.body.price[0],
                $lte: this.body.price[1]
            };
        }

        const res = await this.$http.post(`items/search?limit=${this.limit}&page=${page}`, obj);
        return res.data.docs; // إرجاع المستندات
    },
  },
  created() {
    this.id = this.$route.params.id;

    this.$http.get("offers/" + this.id).then((res) => {
      this.one = res.data;

      this.$emit('updateHead')
    });

     this.loadMore({
    loaded: () => {},
    complete: () => {}
  });


this.$http.post("offers/getOfferCategoriesById",{offerId: this.id
      }).then((res) => {
        this.categoriesList = res.data.data.categoriesDetails
         this.subcategoriesList = res.data.data.subCategoriesDetails;
         this.colorsList = res.data.data.colorsDetails
         this.materialsList = res.data.data.materialsDetails
         this.vendorsList = res.data.data.vendorsDetails;
    })

  },
 computed: {
  filteredData() {
    const itemMap = {};

    this.selectedItemsFromOffer.forEach(item => {
      if (!itemMap[item.itemId]) {
        itemMap[item.itemId] = item;
      }
    });

    return Object.values(itemMap);
  }
}
,

  watch: {
    // "$route.params.id"(val) {
    //   this.id = val;

    //   this.$http.get("offers/" + this.id).then((res) => {
        
        
    //     this.one = res.data;

    //     this.$emit('updateHead')
    //   });

    //   this.$http
    //     .post("offers/getOfferDetailsById/", {
    //       offerId: this.id
    //     })
    //     .then((res) => {
    //       const list = res.data.docs;
    //       this.body.id = [...list.map((x) => x.itemId)];
    //       this.getData();
    //     });
    // },
    "body.categoriesId"(val) {
      if (val) {
        setTimeout(() => this.resetAndReload(), 100);
      }
    },
    "body.subCategoriesId"(val) {
      if (val) {
        setTimeout(() => this.resetAndReload(), 100);
      }
    },
    "body.vendorsId"(val) {
      if (val) {
        setTimeout(() => this.resetAndReload(), 100);
      }
    },
    "body.materialsId"(val) {
      if (val) {
        setTimeout(() => this.resetAndReload(), 100);
      }
    },

    "body.sizeId"(val) {
      if (val) {
        setTimeout(() => this.resetAndReload(), 100);
      }
    },

    "body.colorId"(val) {
      if (val) {
        setTimeout(() => this.resetAndReload(), 100);
      }
    },
    "this.body.price"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    }
  }
};
</script>

<style></style>
