<template>
<div class="products">
    <div class="three-btns container">
        <button @click="$router.push('/')">{{ $t("Main") }} /</button>
        <button>{{ $getByLang(one.name) }}</button>
    </div>

    <div class="change-home position-relative mb-5">
        <div class="container d-flex justify-content-around flex-wrap">
            <p class="mt-3">
                {{ $getByLang(one.bannerText) }}
                <!-- <span class="d-block">
            <span>{{ $t("with") }}</span>
            {{ $t("home her") }}
          </span> -->
            </p>
            <!-- <img
          class="mb-5"
          :src="$baseUploadURL + one.bannerCategoryImage"
          :alt="$getByLang(one.name)"
          style="max-width: 313px"
        /> -->
        </div>
        <!-- <div class="background position-absolute bacj"></div> -->
    </div>

    <sub-category-slider v-if="subcategoriesList.length > 0" :products="subcategoriesList" :subCategoriesId=body.subCategoriesId @fetchSubProducts="fetchSubProducts" />

    <!-- categories and recently arrived -->
    <div class="px-4">
      <div class="row">
        <div class="col-md-3 col-xs-12">
          <button
            @click="() => (showFilter = !showFilter)"
            class="p-2 rounded d-lg-none d-flex align-items-center gap-2"
          >
            <span class="pi pi-filter"></span>
            <!-- <span>Filter</span> -->
          </button>
          <div class="sidebarsearch mt-2 p-3" v-if="showFilter">
            <div class="mb-3">
              <h3>{{ $t("Categories") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of categoriesList"
                :key="'categories_' + item._id"
              >
                <Checkbox
                  :id="'categories_' + item._id"
                  name="categoriesId"
                  :value="item._id"
                  v-model="body.categoriesId"
                  :inputId="'categories_' + item._id"
                />
                <label :for="'categories_' + item._id">{{
                  $getByLang(item.name)
                }}</label>
              </div>
            </div>
          

            

            <div class="mb-3">
              <h3>{{ $t("Colors") }}</h3>

              <span v-for="item of colorsList" :key="'colors_' + item._id">
                <input
                  type="radio"
                  class="btn-check"
                  name="colorId"
                  :id="'colors_' + item._id"
                  autocomplete="off"
                  :value="item._id"
                  v-model="body.colorsList.colorId"
                />
                <label
                  class="btn btn-color"
                  :for="'colors_' + item._id"
                  :style="'border-color: ' + item.value + ' !important'"
                >
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11.5" cy="11.5" r="11.5" :fill="item.value" />
                  </svg>
                </label>
              </span>
            </div>
            <div class="mb-3">
              <h3>{{ $t("the price") }}</h3>
              <div class="row mb-4">
                <div class="col-md-6">{{ Math.round(body.price[1]) }} {{ $t("r.s") }}</div>
                <div class="col-md-6 text-left">
                  {{ Math.round(body.price[0]) }}{{ $t("SAR") }}
                </div>
              </div>
              <div>
                <Slider
                  v-model="body.price"
                  :min="0"
                  :max="100000"
                  :range="true"
                />
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("resources") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of materialsList"
                :key="'materials_' + item._id"
              >
                <Checkbox
                  :id="'materials_' + item._id"
                  name="materialsId"
                  :value="item._id"
                  v-model="body.materialsId"
                  :inputId="'materials_' + item._id"
                />
                <label :for="'materials_' + item._id">
                  {{ $getByLang(item.name) }}
                </label>
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("the shop") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of vendorsList"
                :key="'vendors_' + item._id"
              >
                <Checkbox
                  :id="'vendors_' + item._id"
                  name="vendorsId"
                  :value="item._id"
                  v-model="body.vendorsId"
                  :inputId="'vendors_' + item._id"
                />
                <label :for="'vendors_' + item._id">
                  {{ item.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-xs-12">
          <div class="new m-2">
            <h1 class="cat-head mb-4">{{ $t("recently arrived") }}</h1>
            <div style="min-height: 700px">
              <div class="m-5 text-center" v-if="list.length == 0">
                <img
                  src="../../assets/images/notFoundIllustration.svg"
                  class="mt-5"
                  alt=""
                  style="max-width: 300px"
                />
              </div>
              <div class="row" v-if="list.length > 0">
                <div class="col-md-4 mb-2 p-3" v-for="item of list">
                  <div
                    class="newCard"
                    :style="'cursor:pointer;'"
                    @click="$goToSave('/product/' + item._id)"
                  >
                    <img
                      v-if="item.imLikedThis"
                      src="/images/unlike.svg"
                      class="like"
                      @click="addLike(item)"
                      :alt="$getByLang(item.name)"
                    />
                    <img
                      v-else
                      src="/images/like.svg"
                      class="like"
                      @click="addLike(item)"
                      :alt="$getByLang(item.name)"
                    />
                    <span v-if="item.hasOffer == true" class="itemOfferRight">
                      {{ $t("rival") }}
                      {{ item.offer.offerValue }}%
                    </span>
                     <!-- <span v-if="item.hasOffer == true" class="">
                      {{ $t("rival") }}
                      {{ item.offer.name.ar }}
                    </span> -->

                    <img
                      class="logo"
                      :src="
                        item.colorsList.length > 0 &&
                        item.colorsList[0].colorsImage.length > 0
                          ? $baseUploadURL +
                            item.colorsList[0].colorsImage[0].name
                          : ''
                      "
                      :alt="$getByLang(item.name)"
                    />

                    <div class="newCard-body">
                      <h5 class="text-truncate">{{ $getByLang(item.name) }}</h5>
                      <b-form-rating
                        v-model="item.rate"
                        variant="warning"
                        class="mb-2"
                        readonly
                        inline
                        no-border
                        size="sm"
                        locale="ar-EG"
                      ></b-form-rating>
                      <span> ({{ item.rateAmount }}) </span>

                      <div class="price-container" v-if="item.sizes.length > 0">
                          <p class="current-price" :class="{ hidden: !item.hasOffer }">
                            {{
                             Math.round(item.sizes[0].mainPrice - item.sizes[0].mainPrice * (item.hasOffer ? item.offer.offerValue / 100 : 0))

                            }}
                            {{ $t("r.s") }}
                          </p>
                          <p v-if="item.hasOffer" class="old-price">
                            <del>
                              {{  Math.round(item.sizes[0].mainPrice) }} {{ $t("r.s") }}
                            </del>
                          </p>
                          <p v-else class="single-price">
                            {{  Math.round(item.sizes[0].mainPrice) }} {{ $t("r.s") }}
                          </p>
                        </div>

                      <!-- <p v-if="item.hasOffer == false">{{ item.sizes[0].mainPrice }} {{ $t("SAR") }}</p>
                      <p v-if="item.hasOffer == true && item.sizes.length > 0">
                        {{
                          (item.sizes[0].mainPrice -
                          item.sizes[0].mainPrice * (item.offer.offerValue / 100)).toFixed(2)
                        }}
                        {{ $t("r.s") }}
                        <del class="offeroldprice">
                           {{ item.sizes.length > 0 ? item.sizes[0].mainPrice : 0 }}
                          {{ $t("r.s") }}
                        </del>
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <Paginator
              :pageNumber="pageNum"
              v-if="list.length > 0"
              style="direction: ltr"
              :rows="limit"
              :totalRecords="totalItemsCount"
              @page="onPage($event)"
            ></Paginator> -->

            <infinite-loading @infinite="loadMore" :identifier="pageNum">
              <div slot="no-more">{{ $t('No more data') }}</div>
              <div slot="no-results">{{ $t('No results') }}</div>
            </infinite-loading>


          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

import SubCategorySlider from "../homeComponents/SubCategorySlider.vue";
export default {
    components: {
        SubCategorySlider,
        InfiniteLoading
    },
    head() {
        return {
            title: {
                inner: this.$getByLang(this.one.name),
            },
            meta: [{
                    httpEquiv: 'Cache-Control',
                    content: 'no-cache, no-store, must-revalidate'
                },
                {
                    httpEquiv: 'Pragma',
                    content: 'no-cache'
                },
                {
                    httpEquiv: 'Expires',
                    content: '0'
                }
            ]
        };
    },
    data() {
        return {

            pageNum: sessionStorage.homeHerePageCategories && sessionStorage.homeHerePageCategories === "categories" ?
                parseInt(sessionStorage.homeHerePageNumberCategories || 1) : 1,
            scrollPosition: 0,
            allDataLoaded: false,
            body: {
                categoriesId: [],
                subCategoriesId: [],
                colorsList: {
                    colorId: null,
                },
                sizes: {
                    sizeId: [],
                },
                price: [0, 100000],
                vendorsId: [],
                materialsId: [],
            },
            categoriesList: [],
            subcategoriesList: [],
            colorsList: [],
            vendorsList: [],

            materialsList: [],
            list: [],
            totalItemsCount: 0,

            limit: 0,
            page: 1,
            id: null,
            one: {
                categoriesId: {
                    name: {
                        ar: null,
                        en: null,
                    },
                },
                name: {
                    ar: null,
                    en: null,
                },
                bannerSubCategoryImage: null,
            },

            showFilter: false,
        };
    },
    mounted() {
        if (window.innerWidth > 992) {
            this.showFilter = true;
        }
      
    },

    methods: {

        $goToSave(link) {
            sessionStorage.setItem("homeHerePageCategories", "categories");
            sessionStorage.setItem("homeHerePageNumberCategories", this.pageNum);
            this.$router.push(link);
        },
        addLike(item) {
            if (localStorage.homeHere) {
                this.$http
                    .post("favourite", {
                        itemId: item._id,
                    })
                    .then((res) => {
                        item.imLikedThis = !item.imLikedThis;
                        this.$swal({
                            title: this.$t("Successfully Done"),
                            icon: "success",
                            timer: 3000,
                            showConfirmButton: false,
                        });
                    });
            } else {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: "Must be loged in",
                    life: 3000,
                });
            }
        },
        onPage(event) {
            //event.page: New page number
            this.pageNum = event.page + 1;
            this.getData(event.page + 1);
            sessionStorage.setItem("homeHerePageCategories", "categories");
            sessionStorage.setItem("homeHerePageNumberCategories", this.pageNum);
        },
         async loadMore($state) {
        if (this.allDataLoaded) {
            $state.complete();
            return;
        }

        try {
            const res = await this.getData2(this.pageNum);

            if (res.length) {
                this.list = [...this.list, ...res];
                this.pageNum += 1; // زيادة رقم الصفحة
                $state.loaded();
            } else {
                this.allDataLoaded = true; // تعيين علامة التوقف عند تحميل كل البيانات
                $state.complete();
            }
        } catch (error) {
            console.error("Error loading more data:", error);
            $state.complete();
        }
    },
        
        

        async getData2(page = 1) {
            let obj = {};
            if (this.q) obj.q = this.q;

            if (this.body.categoriesId.length > 0) {
                obj.categoriesId = {
                    $in: this.body.categoriesId
                };
            }
            // if (this.body.subCategoriesId.length > 0) {
            //     obj.subCategoriesId = {
            //         $in: this.body.subCategoriesId
            //     };
            // }
            if (this.body.vendorsId && this.body.vendorsId.length > 0) {
                obj.vendorsId = {
                    $in: this.body.vendorsId
                };
            }
            if (this.body.materialsId.length > 0) {
                obj.materialsId = {
                    $in: this.body.materialsId
                };
            }
            // if (this.body.sizes.sizeId.length > 0) {
            //     obj.sizes = {
            //         sizeId: {
            //             $in: this.body.sizes.sizeId
            //         }
            //     };
            // }
            if (this.body.colorsList.colorId) {
                obj.colorsList = {
                    colorId: this.body.colorsList.colorId
                };
            }

            // ضبط نطاق السعر إذا كان محددًا
            if (this.body.price[0] >= 0 && this.body.price[1] <= 100000) {
                obj.lowestPrice = this.body.price[0];
                obj.highestPrice = this.body.price[1];
            }

             const res = await this.$http.post(`items/search?limit=12&page=${page}`, obj);
                return res.data.docs;
        },

        getData(page = 1) {
            let obj = {};
            if (this.body.categoriesId.length > 0) {
                obj.categoriesId = {
                    $in: this.body.categoriesId
                };
            }
            if (this.body.subCategoriesId.length > 0) {
                obj.subCategoriesId = {
                    $in: this.body.subCategoriesId
                };
            }
            if (this.body.vendorsId && this.body.vendorsId.length > 0) {
                obj.vendorsId = {
                    $in: this.body.vendorsId
                };
            }
            if (this.body.materialsId.length > 0) {
                obj.materialsId = {
                    $in: this.body.materialsId
                };
            }
            if (this.body.sizes.sizeId.length > 0) {
                obj.sizes = {
                    sizeId: {
                        $in: this.body.sizes.sizeId
                    }
                };
            }
            if (this.body.colorsList.colorId) {
                obj.colorsList = {
                    colorId: this.body.colorsList.colorId
                };
            }

            if (this.body.price[0] >= 0 && this.body.price[1] <= 100000) {
                obj.lowestPrice = this.body.price[0];
                obj.highestPrice = this.body.price[1];
            }

            this.$http.post(`items/search?limit=12&page=${page}`, obj).then((res) => {
                this.list = res.data.docs; // تحديث القائمة عند تحميل الصفحة الأولى
                this.totalItemsCount = res.data.totalDocs;
                this.limit = res.data.limit;
            });
        },
        async fetchSubProducts(id) {
            this.body.subCategoriesId = [id];
            // this.categoriesList = await this.$http.get("subCategories/byItems/" + id);
        },
       

    },
    created() {
        console.log("CREATED");

        this.id = this.$route.params.id;
        let obj = {
            categoriesId: this.id
        }
        this.body.categoriesId = [this.id];
        this.$http.get("categories/" + this.id).then((res) => {
            this.one = res.data;
            console.log("CREATED2");
            this.$emit('updateHead')
        });
        this.$http.post("categories/getItemsByCategoryId", {
            categoriesId: this.id
        }).then((res) => {
            this.categoriesList = res.data.data.categoriesDetails
            this.colorsList = res.data.data.colorsDetails
            this.materialsList = res.data.data.materialsDetails
            this.vendorsList = res.data.data.vendorsDetails;
        })
        this.getData();
    },
    watch: {
        "$route.params.id"(val) {
            let obj = {
                categoriesId: val
            }
            this.id = val;
            this.body.categoriesId = [this.id];
            this.$http.get("categories/" + this.id).then((res) => {
                this.one = res.data;

                this.$emit('updateHead')
            });

            this.getData();
            this.$http.post("subcategories/getSubCategoriesByCategories", obj).then((res) => {
                console.log(res.data.docs);

                this.subcategoriesList = res.data.docs;
            });
        },
        "body.categoriesId"(val) {
            if (val) {
                this.useFilter = true
                setTimeout(() => this.getData(), 100);
            }
        },
       
        "body.vendorsId"(val) {
            if (val) {
                setTimeout(() => this.getData(), 100);
            }
        },
        "body.materialsId"(val) {
            if (val) {
                setTimeout(() => this.getData(), 100);
            }
        },

       
        "body.colorsList.colorId"(val) {
            if (val) {

                setTimeout(() => this.getData(), 100);
            }
        },
        "body.price"(val) {
            if (val) {

                setTimeout(() => this.getData(), 100);
            }
        },

    },
};
</script>

<style scoped>
.field-checkbox {
    display: flex;
    align-items: start;
}

.price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 50px;
    /* تحديد ارتفاع أدنى لضمان الاتساق */
}

.current-price.hidden {
    visibility: hidden;
    /* إخفاء السعر العلوي بدون إزالة مكانه */
}

.current-price {
    font-size: 24px;
    /* أو الحجم الذي تراه مناسبًا */
    font-weight: bold;
    color: #000;
    /* اللون الذي تريده للنص */
    margin: 0;
}

.single-price {
    font-size: 24px;
    /* أو الحجم الذي تراه مناسبًا */
    font-weight: bold;
    color: #000;
    /* اللون الذي تريده للنص */
    margin: 0;
}

.old-price {
    font-size: 18px;
    /* أو الحجم الذي تراه مناسبًا */
    color: #888;
    /* اللون الذي تريده للنص المشطوب */
    margin: 0;
}

.old-price del {
    color: #888;
    /* اللون الذي تريده للنص المشطوب */
}
</style>
