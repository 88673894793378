<template>
  <div>
    <footer class="text-center">
      <div class="container-fluid p-4">
        <section class="">
          <div class="row d-flex justify-content-center">
            <div class="foot col-xs-12 col-lg-2 col-12 mb-4 mb-md-0 p-md-0">
              <img
                class="foot-logo"
                src="../assets/images/logo-white.png"
                alt=""
              />
              <div>
                <p class="gray-color m-0">سجل تجاري رقم : 1010853756</p>
                <p class="gray-color m-0">توثيق رقم : 0000098510</p>
                <div >
                  <a href="https://www.saudibusiness.gov.sa/Identity/Account/Login?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DEAuthentication%26redirect_uri%3Dhttps%253A%252F%252FEAuthenticate.saudibusiness.gov.sa%252Fsignin-callback.html%26response_type%3Dcode%26scope%3Dbaladyapi%2520CrBaladyLicenseAPI%2520DataBroadCastApi%2520InvestorPortalApi%2520userprofileapis%2520EAuthenticateAPI%2520servicecatalogapis%2520requestapi%2520proxyapi%2520notificationapi%2520lookupapis%2520delegationapi%2520openid%2520profile%2520roles%2520AttachmentAPI%2520offline_access%26state%3D0cdacc0eab504502a285d500ac7d5cb8%26code_challenge%3DYVUVLE6IV-wUoRP4rd-TGSq0MlUcvjxkwzGuFyxtuUU%26code_challenge_method%3DS256%26response_mode%3Dquery" target="_blank">
                    <img style="width:180px" src="../assets/images/saudiBusiness.png" alt="" />
                  </a>
                </div>
              </div>
              <p class="foot-p m-0">
                {{ $t("followUs") }}
              </p>
              <div class="icons icons-foot-mob">
                <div class="social linkedin">
                  <a :href="settings.linkedinLink" target="_blank">
                    <img src="../assets/images/Group 13.png" alt="" />
                  </a>
                </div>
                <div class="social twitter">
                  <a :href="settings.twitterLink" target="_blank">
                    <img src="../assets/images/Group 12.png" alt="" />
                  </a>
                </div>

                <div class="social instagram">
                  <a :href="settings.instegramLink" target="_blank">
                    <img src="../assets/images/Group 11.png" alt="" />
                  </a>
                </div>
                <div class="social facebook">
                  <a :href="settings.facebookLink" target="_blank">
                    <img src="../assets/images/Group 10.png" alt="" />
                  </a>
                </div>
              </div>
            </div>

            <div class="foot col-lg-2 col-md-6 col-xs-6 mb-4 mb-md-0 mt-5">
              <h5 class="text-uppercase">{{ $t("Important links") }}</h5>

              <ul class="mt-3">
                <li>
                  <router-link to="/">{{ $t("Home") }}</router-link>
                </li>
                <li>
                  <router-link to="/orders">{{ $t("orders") }}</router-link>
                </li>
                <li>
                  <router-link to="/myfavorite">{{
                    $t("Favorite")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/helpcenter">{{
                    $t("helpcenter")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/vendorsRequests">{{
                    $t("vendorsRequests")
                  }}</router-link>
                </li>
              </ul>
            </div>

            <div class="foot col-lg-2 col-md-6 col-xs-6 mb-4 mb-md-0 mt-5">
              <h5 class="text-uppercase">{{ $t("categories") }}</h5>

              <ul class="mt-3">
                <li v-for="item of catList">
                  <router-link :to="'/categories/' + item._id">{{ $getByLang(item.name) }}</router-link>
                </li>
              </ul>
            </div>

            <div class="foot col-lg-2 col-md-6 col-xs-6 mb-4 mb-md-0 mt-5">
              <h5 class="text-uppercase">{{ $t("company") }}</h5>

              <ul class="mt-3">
                <li>
                  <router-link to="/page/aboutUs">{{
                    $t("about us")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/page/policy">{{
                    $t("privacy policy")
                  }}</router-link>
                </li>

                <li>
                  <router-link to="/page/termsAndConditions">{{
                    $t("Terms and Conditions")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/helpcenter?to=contact">{{
                    $t("Connect us")
                  }}</router-link>
                </li>
              </ul>
            </div>

            <div
              class="foot google-play col-lg-2 col-md-6 col-xs-6 mb-4 mb-md-0 mt-5"
            >
              <h5 class="text-uppercase">
                {{ $t("to download application") }}
              </h5>

              <ul class="mt-3">
                <li>
                  <a :href="settings.googlePlayLink" target="_blank">
                    <img src="../assets/images/Google Play badge.png" alt="" />
                  </a>
                </li>
                <li>
                  <a :href="settings.appleStoreLink" target="_blank">
                    <img src="../assets/images/App Store badge.png" alt="" />
                  </a>
                </li>
                <li>
                  <a :href="settings.huaweiLink" target="_blank">
                    <img
                      src="../assets/images/huawei badge.png"
                      alt=""
                      style="width: 135px"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <div class="border-top pt-3 mt-3">
          <div
            class="row align-items-center justify-content-md-between justify-content-center"
          >
            <div class="col-md-1 col-4">
              <img src="../assets/payment/1.png" height="50" width="50" />
            </div>
            <div class="col-md-1 col-4">
              <img src="../assets/payment/2.png" height="50" width="50" />
            </div>
            <div class="col-md-1 col-4">
              <img src="../assets/payment/3.png" height="50" width="50" />
            </div>
            <div class="col-md-1 col-4">
              <img src="../assets/payment/4.png" height="50" width="50" />
            </div>
            <div class="col-md-1 col-4">
              <img src="../assets/payment/5.png" height="50" width="50" />
            </div>
            <!-- <div class="col-md-1 col-4">
              <img src="../assets/payment/tabby.png" height="50" width="50" />
            </div> -->
            <div class="col-md-1 col-4">
              <img src="../assets/payment/tamara.png" height="50" width="50" />
            </div>
          </div>
        </div>
      </div>
    </footer>

    <a :href="`https://wa.me/${settings.whatsapp}`" rel="noopener noreferrer" target="_blank" class="social_style"
      data-v-8743ee72="">
      <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg"
        data-v-8743ee72="">
        <path
          d="M18.5298 5H5.89404C5.40029 5 5 5.35819 5 5.8C5 6.24181 5.40029 6.6 5.89404 6.6H18.5298C19.0236 6.6 19.4238 6.24181 19.4238 5.8C19.4238 5.35819 19.0236 5 18.5298 5ZM22.106 8.2H5.89404C5.40029 8.2 5 8.55819 5 9C5 9.44181 5.40029 9.8 5.89404 9.8H22.106C22.5997 9.8 23 9.44181 23 9C23 8.55819 22.5997 8.2 22.106 8.2ZM14.9536 11.4H5.89404C5.40029 11.4 5 11.7582 5 12.2C5 12.6418 5.40029 13 5.89404 13H14.9536C15.4474 13 15.8477 12.6418 15.8477 12.2C15.8477 11.7582 15.4474 11.4 14.9536 11.4Z"
          fill="white"></path>
        <path
          d="M6.34375 25.375C6.10347 25.3748 5.8731 25.2792 5.7032 25.1093C5.5333 24.9394 5.43774 24.709 5.4375 24.4688V19.9375H2.71875C1.21981 19.9375 0 18.7177 0 17.2188V2.71875C0 1.21981 1.21981 0 2.71875 0H24.4688C25.9677 0 27.1875 1.21981 27.1875 2.71875V17.2188C27.1875 18.7177 25.9677 19.9375 24.4688 19.9375H13.4587L6.91016 25.1765C6.74945 25.3052 6.54964 25.3752 6.34375 25.375ZM2.71875 1.8125C2.2185 1.8125 1.8125 2.21941 1.8125 2.71875V17.2188C1.8125 17.7181 2.2185 18.125 2.71875 18.125H6.34375C6.5841 18.125 6.81461 18.2205 6.98457 18.3904C7.15452 18.5604 7.25 18.7909 7.25 19.0312V22.5837L12.5742 18.3235C12.735 18.1949 12.9348 18.1249 13.1406 18.125H24.4688C24.969 18.125 25.375 17.7181 25.375 17.2188V2.71875C25.375 2.21941 24.969 1.8125 24.4688 1.8125H2.71875Z"
          fill="white"></path>
      </svg>
      <p class="hz-fs-16" data-v-8743ee72="">{{ $t("need help") }}</p>
    </a>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "app-footer",

  props: {
    list: []
  },
  components: { router },
  data() {
    return {
      catList: [],
      settings: {
        footerText: null,
        facebookLink: null,
        linkedinLink: null,
        twitterLink: null,
        instegramLink: null,
        googlePlayLink: null,
        appleStoreLink: null,
        websiteEmail: null,
        taxValue: null,
        whatsapp: null
      }
    };
  },
  created() {
    this.$http.get("categories?limit=4").then((res) => {
      this.catList = res.data.docs;
    });
    this.$http.get("settings?limit=4").then((res) => {
      this.settings =
        res.data.docs.length > 0
          ? res.data.docs[0]
          : {
              footerText: null,
              facebookLink: null,
              linkedinLink: null,
              twitterLink: null,
              instegramLink: null,
              googlePlayLink: null,
              appleStoreLink: null,
              websiteEmail: null,
              taxValue: null,
              whatsapp: null
            };
    });
  }
};
</script>

<style>
footer {
  background-color: #313131;
}

footer section div h5 {
  color: #d9d9d9;
}
ul {
  list-style: none;
  /* padding-left: initial; */
}

footer section div ul li a {
  text-decoration: none;
  color: #7a7a7a;
  font-size: 14px;
  line-height: 40px;
}
img.foot-logo {
  width: -webkit-fill-available;
}
@media (max-width: 768px) {
  img.foot-logo {
    width: 50%;
    margin: 10px auto;
  }
}
.gray-color {
  color: #bfbfbf;
  font-size: 16px;
  line-height: 40px;
}
.foot-p {
  color: #bfbfbf;
  font-size: 16px;
  line-height: 40px;
}
div.icons .social {
  color: #313131;
  font-size: 20px;
}
.social {
  display: inline-block;
  margin: 2px;
}

div.icons .social img {
  max-width: 30px;
}

.foot h5 {
  color: #d9d9d9;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.02em;
}
.google-play ul li {
  margin-bottom: 16px;
}
.social.linkedin {
  padding-top: 9px;
}


/* whatsapp style  */



     .social_style {
        align-items: center;
        background-color: #f59f00;
        border-radius: 25px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, .251);
        display: flex;
        height: 52px;
        justify-content: center;
        margin-top: 10px;
        max-width: -moz-max-content;
        max-width: max-content;
        padding:0 12px;
        position: fixed !important;
        bottom: 3vh !important;
        left: 5vw !important;

    }

   .social_style, .social_style p {
        overflow: hidden;
        position: relative;
        transition:all .4s ease-in-out
    }

    .social_style p {
        color: #fff;
        max-width: 0;
        white-space:nowrap
    }

   .social_style:hover {
        padding:0 14px
    }

  .social_style:hover p {
        margin-right: 8px;
        max-width:200px
    }

    .social_style:hover svg {
        transition:all .4s ease-in-out
    }

    @media screen and (max-width: 768px) {
        .social_style {
            border-radius: 50% !important;
            height: 57px !important;
            max-width:60px !important
        }

        .social_style svg {
            margin-top:3px
        }

        .social_style p {
            display:none
        }

        .social_PDP {
            bottom:13%
        }
    }



   .social_style, html[lang=en] .social_style {
        flex-direction:row-reverse
    }

     .social_style:hover p {
        margin: 8px;
    }

     .social_style svg {
        transform: rotateY(180deg)
    }

    .ltr .social_style {
      left: auto !important;
      right: 5vw !important;
    }
</style>
