import axios from 'axios';



export let baseURL = '';
let imageLink='';
if (window.location.href.includes('stage')) {
  baseURL = 'https://api-stage.herehome.sa';
  imageLink='https://herehome-uploads-stage.s3.amazonaws.com/';
} else if (window.location.href.includes('localhost')) {
  baseURL = 'http://localhost:7001';
  imageLink='https://herehome-dev.s3.amazonaws.com/';
} else {
  baseURL = 'https://api-temp.herehome.sa';
  imageLink='https://herehome-uploads-prod.s3.amazonaws.com/';
}



export const baseUploadURL = imageLink;

const user = localStorage.homeHere ? JSON.parse(localStorage.homeHere) : '';

export const HTTP = axios.create({
  baseURL: `${baseURL}/api/`,
  headers: {
    Authorization: `Bearer ${user?.token}`,
    lang: localStorage.lang || 'ar',
    userid: user?._id,
  },
});
